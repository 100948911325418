import React, { Component } from 'react';
import { Button, Collapse } from 'react-bootstrap';

import { downloadFile } from './DownloadFile';

const RGBase = 'https://www.resmigazete.gov.tr/';

export class MevzuatList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [], type: "", id: "", open: false, openInfo: {}
        };

        this.handleDownload = this.handleDownload.bind(this);
    }

    componentDidMount() {
        let items = this.props.items;
        let type = this.props.type;
        let id = this.props.id;
        let open = this.props.open;
        this.setState({ items: items, type: type, id: id, open: open });
    }

    handleDownload = (id, fileName, e) => {
        e.preventDefault();
        downloadFile(id, fileName, "Mevzuat");
    };

    renderResults(items, type, openInfo) {
        let sayiVisible = type === 'Tebliğ';

        let results = [];
        items.forEach(k => {
            let idx = k.id;
            let sayiHeader = sayiVisible ? <div>Sayı</div> : "";
            let sayiContent = sayiVisible ? <div>{k.sayi}</div> : "";
            let fileLink = <a className="btn btn-link" href={k.link} target='_blank' rel='noopener noreferrer' title='Görüntüle'><i className="material-icons" aria-hidden='true'>description</i></a>
            let downloadLink = <button className="btn btn-link" onClick={(e) => this.handleDownload(k.contentID, idx, e)} title='İndir'><i className="material-icons" aria-hidden='true'>download</i></button>
            let rgLink = <a href={RGBase + k.resmiGazeteTarih} target='_blank' rel='noopener noreferrer' title='Resmi Gazete'>{k.resmiGazeteTarih}</a>
            let rgSayi = k.resmiGazeteMukerrer ? <span>{k.resmiGazeteSayi} ({k.resmiGazeteMukerrer})</span> : <span>{k.resmiGazeteSayi}</span>
            results.push(<div key={k.id}>
                <Button variant="link" onClick={() => { openInfo[idx] = !openInfo[idx]; this.setState({ openInfo: openInfo }) }} aria-expanded="false" aria-controls={idx}>{k.title}</Button>
                <span>{fileLink}</span>
                <span>{downloadLink}</span>
                <Collapse in={openInfo[idx]}>
                    <div id={idx}>
                        <div className="d-inline-flex flex-row typeResultHead">
                            {sayiHeader}<div>Resmi Gazete Tarihi</div><div>Resmi Gazete Sayı</div><div>Kısım</div><div>Bölüm</div><div>Dosya</div>
                        </div>
                        <div className="d-inline-flex flex-row typeResultItem">
                            {sayiContent}<div>{rgLink}</div><div>{rgSayi}</div><div>{k.kisim}</div><div>{k.bolum}</div><div>{fileLink}</div>
                        </div>
                    </div>
                </Collapse>
            </div>);
        });

        return (
            <>
                {results}
            </>
        );
    }

    render() {
        const { items, type, id, open, openInfo } = this.state;
        let haveItems = items && items.length > 0;
        let contents = haveItems ? this.renderResults(items, type, openInfo) : "";

        let header = haveItems ? <span className="search-result-title">{type}</span> : "";
        return (
            <div className={haveItems ? 'd-block' : 'd-none'}>
                <Button variant="light" onClick={() => this.setState({ open: !open })} className="btn-search-result-title" aria-expanded="false" aria-controls={id}>
                    <i className="material-icons">{!open ? "add_box" : "indeterminate_check_box"}</i> {header}
                </Button>
                <Collapse in={open}>
                    <div id={id} className="search-result-list mt-2">
                        {contents}
                    </div>
                </Collapse>
            </div>
        );
    }
}