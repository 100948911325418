import React, { Component } from 'react';
import Config from './settings/config';

const API = Config.apiURL;

export class KisimBolumDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = { id: "", name: "", parentId: 0, value: "", items: [] };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const id = this.props.id;
        const name = this.props.name;
        const parentId = this.props.parentId;
        this.setState({ id: id, name: name, parentId: parentId });

        this.getKisimBolumItems(name, parentId);
    }

    componentDidUpdate(prevProps) {
        const name = this.props.name;
        const parentId = this.props.parentId;
        if (parentId !== prevProps.parentId)
        {
            this.getKisimBolumItems(name, parentId);
        }
    }

    handleChange(event) {
        const name = this.state.name;
        if (name === 'kisim')
            this.props.handleChangeKisim(event.target.value);
        else
            this.props.handleChangeBolum(event.target.value);
        this.setState({ value: event.target.value });
    }

    render() {
        const { id, name, items } = this.state;
        let title = name === 'kisim' ? "Kisimlar" : "Bölümler";

        let optionItems = items.length > 0 ? items.map((item) =>
            <option key={item.id} value={item.id}>{item.title}</option>
        ) : "";

        return (
            <>
                <select id={id} name={name} className="form-control" onChange={this.handleChange} value={this.state.value} aria-label={name}>
                    <option key="0" value="0">Tüm {title}</option>
                    {optionItems}
                </select>
            </>
        );
    }

    async getKisimBolumItems(query, parentId) {
        let url = API + query + "/List";
        if (query === 'bolum')
            url += "/" + parentId;
        var result = [];
        try {
            const response = await fetch(url);
            const data = await response.json();
            result = data;
        }
        catch (error) {
        }
        this.setState({ items: result });
    }
}