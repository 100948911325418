import Config from './settings/config'

const API = Config.apiURL;

function downloadBlobFile(data, filename, mime) {
    const blob = new Blob([data], { type: mime });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export async function downloadFile(id, fileName, controller) {
    let query = controller + "/File";
    var contentType = "application/pdf"
    fileName = fileName + '.pdf';
    await fetch(API + query + "/" + id)
        .then(function (response) {
            contentType = response.headers.get('content-type');
            return response.blob();
        })
        .then(function (data) {
            const file = new Blob(
                [data],
                { type: contentType });
            downloadBlobFile(file, fileName, contentType);
        })
        .catch(function (error) { });
}