/* firstItem is the default top item */
var sortOnKeysWithDefault = function (dict, firstItem) {
    var items = Object.keys(dict).map(function (key) {
        return key;
    }).sort();

    var tempDict = {};
    if (dict[firstItem])
        tempDict[firstItem] = dict[firstItem];
    for (var i = 0; i < items.length; i++) {
        if (items[i]) // remove empty key
            tempDict[items[i]] = dict[items[i]];
    }
    return tempDict;
};
export default sortOnKeysWithDefault;