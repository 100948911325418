import React, { Component } from 'react';
import { Alert, Accordion, Card, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';
import Config from './settings/config';
import { MevzuatList } from './MevzuatList';
import { MevzuatContentList } from './MevzuatContentList';
import { IlkeKarariList } from './IlkeKarariList';
import { RehberList } from './RehberList';
import { KisimBolumDropdown } from './KisimBolumDropdown';
import { KonuDropdown } from './KonuDropdown';
import "react-datepicker/dist/react-datepicker.css";

const API = Config.apiURL;
const QUERY = 'Search';

export class Search extends Component {
    constructor(props) {
        super(props);

        //wordConnector: "" removed
        this.state = { results: [], searchText: "", searched: false, searchField: "all", kisimId: 0, bolumId: 0, konuGrubuId: 0, konuId: 0, rgDateBegin: null, rgDateEnd: null, kkDateBegin: null, kkDateEnd: null, type: "", keywords: "", selectedListType: "type", loading: null };

        this.handleClear = this.handleClear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDetailedSubmit = this.handleDetailedSubmit.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        //this.handleChangeWordConnector = this.handleChangeWordConnector.bind(this);
        this.handleChangeSearchField = this.handleChangeSearchField.bind(this);
        this.handleChangeListType = this.handleChangeListType.bind(this);
        this.handleChangeRGDateBegin = this.handleChangeRGDateBegin.bind(this);
        this.handleChangeRGDateEnd = this.handleChangeRGDateEnd.bind(this);
        this.handleChangeKKDateBegin = this.handleChangeKKDateBegin.bind(this);
        this.handleChangeKKDateEnd = this.handleChangeKKDateEnd.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeKeywords = this.handleChangeKeywords.bind(this);

        this.handleChangeKisim = this.handleChangeKisim.bind(this);
        this.handleChangeBolum = this.handleChangeBolum.bind(this);

        this.handleChangeKonuGrubu = this.handleChangeKonuGrubu.bind(this);
        this.handleChangeKonu = this.handleChangeKonu.bind(this);
    }

    componentDidMount() {
        setDefaultLocale("tr", tr);
        this.setState({ loading: true });
        this.getAll();
    }

    handleClear() {
        this.setState({ searchText: "", loading: true, searched: false });
        this.getAll();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!event.target.checkValidity())
            return;
        const searchText = this.state.searchText;
        //const wordConnector = this.state.wordConnector;
        const searchField = this.state.searchField;
        this.setState({ loading: true, searched: true });
        this.doSearch(searchText, searchField);
    }

    handleDetailedSubmit = (event) => {
        event.preventDefault();
        if (!event.target.checkValidity())
            return;
        this.setState({ loading: true, searched: true });

        const searchText = this.state.searchText;
        //const wordConnector = this.state.wordConnector;
        const searchField = this.state.searchField;
        const kisimId = this.state.kisimId;
        const bolumId = this.state.bolumId;
        const konuGrubuId = this.state.konuGrubuId;
        const konuId = this.state.konuId;
        const rgDateBegin = this.state.rgDateBegin;
        const rgDateEnd = this.state.rgDateEnd;
        const kkDateBegin = this.state.kkDateBegin;
        const kkDateEnd = this.state.kkDateEnd;
        const type = this.state.type;
        const keywords = this.state.keywords;

        //wordConnector
        this.doDetailedSearch(searchText, searchField, kisimId, bolumId, konuGrubuId, konuId, rgDateBegin, rgDateEnd, kkDateBegin, kkDateEnd, type, keywords);
    }

    handleChangeSearch(event) {
        this.setState({ searchText: event.target.value });
    }

    //handleChangeWordConnector(event) {
    //    this.setState({ wordConnector: event.target.value });
    //}

    handleChangeSearchField(event) {
        this.setState({ searchField: event.target.value });
    }

    handleChangeListType(event) {
        this.setState({ selectedListType: event.target.value });
    }

    handleChangeRGDateBegin = date => {
        this.setState({ rgDateBegin: date });
    }

    handleChangeRGDateEnd = date => {
        this.setState({ rgDateEnd: date });
    }

    handleChangeKKDateBegin = date => {
        this.setState({ kkDateBegin: date });
    }

    handleChangeKKDateEnd = date => {
        this.setState({ kkDateEnd: date });
    }

    handleChangeType(event) {
        this.setState({ type: event.target.value });
    }

    handleChangeKeywords(event) {
        this.setState({ keywords: event.target.value });
    }

    handleChangeKisim(value) {
        let kisimId = parseInt(value);
        this.setState({ kisimId: kisimId });
    }

    handleChangeBolum(value) {
        let bolumId = parseInt(value);
        this.setState({ bolumId: bolumId });
    }

    handleChangeKonuGrubu(value) {
        let konuGrubuId = parseInt(value);
        this.setState({ konuGrubuId: konuGrubuId });
    }

    handleChangeKonu(value) {
        let konuId = parseInt(value);
        this.setState({ konuId: konuId });
    }

    static renderResults(items, searched) {
        var kanunItems = [];
        var tebligItems = [];
        var yonetmelikItems = [];
        var kararItems = [];
        var digerItems = [];
        var ilkekararItems = [];
        var rehberItems = [];
        items.forEach(item => {
            if (item.tur === 'Kanun')
                kanunItems.push(item);
            else if (item.tur === 'Tebliğ')
                tebligItems.push(item);
            else if (item.tur === 'Yönetmelik')
                yonetmelikItems.push(item);
            else if (item.tur === 'Diğer Karar')
                kararItems.push(item);
            else if (item.tur === 'Diğer')
                digerItems.push(item);
            else if (item.contentSource === 'IlkeKarari')
                ilkekararItems.push(item);
            else if (item.contentSource === 'Rehber')
                rehberItems.push(item);
        });

        let kanunResults = <MevzuatList items={kanunItems} type='Kanun' id='kanun' open={searched} />;
        let tebligResults = <MevzuatList items={tebligItems} type='Tebliğ' id='tebligler' open={searched} />;
        let yonetmelikResults = <MevzuatList items={yonetmelikItems} type='Yönetmelik' id='yonetmelikler' open={searched} />;
        let kararResults = <MevzuatList items={kararItems} type='Diğer Karar' id='kararlar' open={searched} />;
        let digerResults = <MevzuatList items={digerItems} type='Diğer' id='diger' open={searched} />;
        let ilkekararResults = <IlkeKarariList items={ilkekararItems} open={searched} />
        let rehberResults = <RehberList items={rehberItems} open={searched} />

        return (
            <>
                <div className="d-flex flex-column">
                    <div className="mb-2">
                        {kanunResults}
                    </div>
                    <div className="mb-2">
                        {tebligResults}
                    </div>
                    <div className="mb-2">
                        {yonetmelikResults}
                    </div>
                    <div className="mb-2">
                        {kararResults}
                    </div>
                    <div className="mb-2">
                        {ilkekararResults}
                    </div>
                    <div className="mb-2">
                        {rehberResults}
                    </div>
                    <div>
                        {digerResults}
                    </div>
                </div>
            </>
        );
    }

    static renderResultsByContent(items, searched) {
        let content = <MevzuatContentList items={items} allOpen={searched} />;

        return (
            <>
                { content }
            </>
        );
    }

    render() {
        let items = this.state.results;
        let searched = this.state.searched;
        let selectedListType = this.state.selectedListType;
        let contents = this.state.loading != null && this.state.loading ? <Alert variant="info">Arama yapılıyor...</Alert> : "";
        if (this.state.loading != null && !this.state.loading) {
            if (items.length > 0)
                contents = selectedListType === 'type' ? Search.renderResults(items, searched) : Search.renderResultsByContent(items, searched);
            else
                contents = <Alert variant="warning">Herhangi bir sonuç bulunamadı</Alert>;
        }

        let today = new Date();
        let beginning = new Date(1982, 0, 1);
        let rgDateBegin = this.state.rgDateBegin != null ? this.state.rgDateBegin : beginning;
        let rgDateEnd = this.state.rgDateEnd != null ? this.state.rgDateEnd : today;
        let kkDateBegin = this.state.kkDateBegin != null ? this.state.kkDateBegin : beginning;
        let kkDateEnd = this.state.kkDateEnd != null ? this.state.kkDateEnd : today;

        //show hide according to Type
        let tur = this.state.type;
        let types = ["Tebliğ", "Yönetmelik", "Diğer Karar", "Kanun", "Diğer"];
        let rgDateFieldVisible = (tur && types.includes(tur)) || !tur ? "d-block" : "d-none";
        let subjectVisible = tur && (tur === "İlke Kararı" || tur === "Kurul Kararı" || tur === "Rehber") ? "d-block" : "d-none";
        let kurulKararVisible = tur && (tur === "İlke Kararı" || tur === "Kurul Kararı") ? "d-block" : "d-none";

        const popoverInfo = (
            <Popover id="popover-info">
                <Popover.Title as="h3">
                    Yardım
                </Popover.Title>
                <Popover.Content>
                    Arama yaparken kelimeleri
                    <ul>
                        <li>tırnak içine alabilirsiniz, örn: "bilgi sistemleri bağımsız denetimi"</li>
                        <li>tüm kelimeleri içermesi için VE ile bağlayabilirsiniz, örn: "aracı kurum" ve "kamuyu aydınlatma"</li>
                        <li>herhangi bir kelimeyi içermesi için VEYA ile bağlayabilirsiniz, örn: kuruluş veya ortaklık</li>
                    </ul>
                    <p>
                        <a href="/Sayfa/yardim" target="_self" title="Yardım"><i className="material-icons">help</i>&nbsp;Detaylı Yardım dokümanı için tıklayınız</a>
                    </p>
                </Popover.Content>
            </Popover>
        );

        registerLocale('tr', tr);
        return (
            <>
                <article>
                    <div className="d-flex flex-column">
                        <form onSubmit={this.handleSubmit}>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row">
                                    <div className="input-group mb-3 search-container pt-4">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text form-control"><i className="material-icons" aria-hidden="true">search</i></div>
                                        </div>
                                        <input type="search" name="search" value={this.state.searchText} onChange={this.handleChangeSearch} placeholder="örneğin: Yatırım Fonlarına İlişkin Esaslar" aria-label="Ara" className="form-control" />
                                        <div className="input-group-append">
                                            <button className="btn btn-info btn-search" type="submit"><i className="material-icons" aria-hidden="true">search</i>&nbsp;ARA</button>
                                        </div>
                                    </div>
                                    <div className="d-none d-sm-block ml-3 mb-3 pt-4">
                                        <OverlayTrigger trigger="click" rootClose placement="left-start" overlay={popoverInfo}>
                                            <Button variant="info"><i className="material-icons-outlined" aria-hidden="true">info</i></Button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="form-group form-inline pl-2">
                                    <input type="radio" name="searchField" id="searchFieldAll" value="all" checked={this.state.searchField === "all"} onChange={this.handleChangeSearchField} />
                                    <label htmlFor="searchFieldAll">&nbsp;İçerik ve Başlıkta</label>&nbsp;&nbsp;
                                    <input type="radio" name="searchField" id="searchFieldTitle" value="title" checked={this.state.searchField === "title"} onChange={this.handleChangeSearchField} />
                                    <label htmlFor="searchFieldTitle">&nbsp;Başlıkta</label>&nbsp;&nbsp;
                                    <input type="radio" name="searchField" id="searchFieldContent" value="content" checked={this.state.searchField === "content"} onChange={this.handleChangeSearchField} />
                                    <label htmlFor="searchFieldContent">&nbsp;İçerikte</label>
                                </div>
                            </div>
                            <div className="mt-1">
                                <Accordion>
                                    <Card>
                                        <Card.Header className="detailed-search-header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                Detaylı Arama
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className="detailed-search-body">
                                                <div className="d-flex flex-column">
                                                    <div className="mb-2 form-group form-inline">
                                                        <KisimBolumDropdown id="selectKisim" name="kisim" handleChangeKisim={this.handleChangeKisim} />&nbsp;
                                                        <KisimBolumDropdown id="selectBolum" name="bolum" handleChangeBolum={this.handleChangeBolum} parentId={this.state.kisimId} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <div className="form-group form-inline">
                                                            <label htmlFor="selectType">Türü : </label>&nbsp;
                                                            <select id="selectType" name="tur" className="form-control" onChange={this.handleChangeType} value={this.state.type}>
                                                                <option value="">Tüm Türler</option>
                                                                <option value="Tebliğ">Tebliğ</option>
                                                                <option value="Yönetmelik">Yönetmelik</option>
                                                                <option value="Diğer Karar">Diğer Karar</option>
                                                                <option value="Kurul Kararı">Kurul ve İlke Kararları</option>
                                                                <option value="Rehber">Rehber</option>
                                                            </select>
                                                            &nbsp;&nbsp;
                                                            <span className="small text-muted">* Konu alanı sadece Kurul ve İlke Kararları ile Rehber türleri seçildiğinde kullanılabilir</span>
                                                        </div>
                                                    </div>
                                                    <div className={rgDateFieldVisible + " mb-2"}>
                                                        <div className="d-inline-flex flex-row">
                                                            <div className="pt-2">Resmi Gazete Tarihi : &nbsp;</div>
                                                            <div><DatePicker id="dateBegin" name="dateBegin" locale="tr" selected={rgDateBegin} onChange={this.handleChangeRGDateBegin} dateFormat="dd.MM.yyyy" popperPlacement="right" className="form-control" aria-label="Resmi Gazete Başlangıç Tarihi" /></div>
                                                            <div className="ml-2"><DatePicker id="dateEnd" name="dateEnd" locale="tr" selected={rgDateEnd} onChange={this.handleChangeRGDateEnd} dateFormat="dd.MM.yyyy" popperPlacement="right" className="form-control" aria-label="Resmi Gazete Bitiş Tarihi" /></div>
                                                        </div>
                                                    </div>
                                                    <div className={kurulKararVisible + " mb-2"}>
                                                        <div className="d-inline-flex flex-row">
                                                            <div className="pt-2">Kurul Karar Tarihi : &nbsp;</div>
                                                            <div><DatePicker id="dateKurulKararBegin" name="dateKurulKararBegin" locale="tr" selected={kkDateBegin} onChange={this.handleChangeKKDateBegin} dateFormat="dd.MM.yyyy" popperPlacement="right" className="form-control" aria-label="Kurul Karar Başlangıç Tarihi" /></div>
                                                            <div className="ml-2"><DatePicker id="dateKurulKararEnd" name="dateKurulKararEnd" locale="tr" selected={kkDateEnd} onChange={this.handleChangeKKDateEnd} dateFormat="dd.MM.yyyy" popperPlacement="right" className="form-control" aria-label="Kurul Karar Bitiş Tarihi" /></div>
                                                        </div>
                                                    </div>
                                                    <div className={subjectVisible + " mb-2 form-group form-inline"}>
                                                        <KonuDropdown id="selectKonuGrubu" name="konugrubu" handleChangeKonuGrubu={this.handleChangeKonuGrubu} />&nbsp;
                                                        <KonuDropdown id="selectKonu" name="konu" handleChangeKonu={this.handleChangeKonu} parentId={this.state.konuGrubuId} />
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-info btn-search" type="submit" onClick={this.handleDetailedSubmit}><i className="material-icons" aria-hidden="true">search</i>&nbsp;Detaylı Ara</button>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="mt-3">
                                <div className="d-flex flex-row">
                                    <div className="pt-2 pl-2 d-flex">
                                        <div className="flex-row mr-2">
                                            <input type="radio" id="radioListByType" name="radioGroupListBy" value="type" checked={selectedListType === 'type'} onChange={this.handleChangeListType} /><label htmlFor="radioListByType">&nbsp;Türe Göre Listele</label>
                                        </div>
                                        <div className="flex-row">
                                            <input type="radio" id="radioListByContent" name="radioGroupListBy" value="content" checked={selectedListType === 'content'} onChange={this.handleChangeListType} /><label htmlFor="radioListByContent">&nbsp;Kanunun Kısımlarına Göre Listele</label>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <button className={this.state.searched ? "btn btn-info btn-clear" : "d-none"} type="button" onClick={this.handleClear}><i className="material-icons" aria-hidden="true">clear_all</i>&nbsp;Sonuçları Temizle</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="mt-3">
                            {contents}
                        </div>
                    </div>
                </article>
            </>
        );
    }

    async getAll() {
        let url = API + QUERY + "/All";
        var result = [];
        try {
            const response = await fetch(url);
            const data = await response.json();
            result = data;
        }
        catch (error) {
        }

        this.setState({ results: result, loading: false });
    }

    async doSearch(searchText, searchField) {
        if (searchText.includes("/"))
            searchText = searchText.replace("/", "%2F");
        let url = API + QUERY + "/" + searchText + "?sf=" + searchField;
        var result = [];
        try {
            const response = await fetch(url);
            const data = await response.json();
            result = data;
        }
        catch (error) {
        }

        this.setState({ results: result, loading: false });
    }

    async doDetailedSearch(searchText, searchField, kisimId, bolumId, konuGrubuId, konuId, rgDateBegin, rgDateEnd, kkDateBegin, kkDateEnd, type, keywords) {
        let url = API + QUERY;
        var result = [];
        var searchItem = { "id": searchText, "searchField": searchField, "kisimId": kisimId, "bolumId": bolumId, "konuGrubuId": konuGrubuId, "konuId": konuId, "rgDateBegin": rgDateBegin, "rgDateEnd": rgDateEnd, "kkDateBegin": kkDateBegin, "kkDateEnd": kkDateEnd, "type": type, "keywords": keywords };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(searchItem)
        };

        try {
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            result = data;
        }
        catch (error) {
            alert(error);
        }

        this.setState({ results: result, loading: false });
    }
}