import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import footerlogo from '../images/spklogo.png';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        var theYear = new Date().getFullYear();
        return (
            <footer className="border-top footer">
                <div className="mainContainer">
                    <div className="row">
                        <div className="col-sm d-flex flex-column">
                            <div className="address">
                                <i className="material-icons" aria-hidden="true">location_on</i> <strong>Merkez:</strong> Mustafa Kemal Mahallesi, Dumlupınar Bulvarı (Eskişehir Yolu) No:156 06530 Çankaya / ANKARA<br />
                                <i className="material-icons" aria-hidden="true">phone_in_talk</i> Telefon: +90 (312) 292 90 90 &nbsp; <i className="material-icons" aria-hidden="true">print</i> Faks: +90 (312) 292 90 00
                            </div>
                        </div>
                        <div className="col-sm d-flex flex-column">
                            <div className="address">
                                <i className="material-icons">location_on</i> <strong>İstanbul Temsilciliği:</strong> Harbiye Mah. Asker Ocağı Cad. No:6 Süzer Plaza 34367 Şişli / İSTANBUL<br />
                                <i className="material-icons">phone_in_talk</i> Telefon: +90 (212) 334 55 00 &nbsp; <i className="material-icons" aria-hidden="true">print</i> Faks: +90 (212) 334 56 00
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container-fluid">
                        <div className="d-flex flex-row-reverse">
                            <div>
                                <img className="footer-logo" src={footerlogo} alt="SPK" />
                            </div>
                            <div className="footer-links">
                                <a href="https://spk.gov.tr/Home/Iletisim" target="_blank" rel="noopener noreferrer" title="İletişim">İletişim</a>&nbsp;|&nbsp;
                                <a href="https://spk.gov.tr/Sayfa/AltSayfa/1268" target="_blank" rel="noopener noreferrer" title="Kullanım">Kullanım</a>&nbsp;|&nbsp;
                                <Link to="/Sayfa/yardim">Yardım</Link> - &copy; {theYear} - <a href="https://spk.gov.tr" target="_blank" title="Sermaye Piyasası Kurulu web sitesi" rel="noopener noreferrer"><span className="site-title">Sermaye Piyasası Kurulu</span></a>&nbsp;&nbsp;
                            </div>
                            <div className="flex-grow-1"></div>
                            <div className="social mt-2">
                                <a href="https://spk.gov.tr/RSS" target="_blank" title="SPK RSS feed" aria-label="SPK RSS feed"><i className="material-icons" aria-hidden="true">rss_feed</i></a>&nbsp;&nbsp;
                                <a href="https://twitter.com/spkgovtr" target="_blank" title="SPK Twitter" aria-label="SPK Twitter hesabı" className="btn btn-link twitter">SPK Twitter</a>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
