import React, { Component } from 'react';
import { Button, Collapse } from 'react-bootstrap';

import { downloadFile } from './DownloadFile';

const TebligBase = '/Mevzuat/Dosya/';
const BulletinBase = 'https://spk.gov.tr/Bulten/Goster?';

export class IlkeKarariList extends Component {

    constructor(props) {
        super(props);

        this.state = { items: [], open: false, openInfo: {} };

        this.handleDownload = this.handleDownload.bind(this);
    }

    componentDidMount() {
        let items = this.props.items;
        let open = this.props.open;
        this.setState({ items: items, open: open });
    }

    handleDownload = (id, fileName, e) => {
        e.preventDefault();
        downloadFile(id, fileName, "IlkeKarari");
    };

    renderResults(items, openInfo) {
        let results = [];
        items.forEach(k => {
            let idx = k.id;
            let bultenLink = k.bultenYili && k.bultenYili != null ? <a href={BulletinBase + "year=" + k.bultenYili + "&no=" + k.bultenNo} target="_blank" rel="noopener noreferrer">{k.bultenYili + "/" + k.bultenNo}</a> : ""
            let fileLink = <a className="btn btn-link" href={k.link} target='_blank' rel='noopener noreferrer' title='Görüntüle'><i className="material-icons" aria-hidden='true'>description</i></a>
            let downloadLink = <button className="btn btn-link" onClick={(e) => this.handleDownload(k.contentID, idx, e)} title='İndir'><i className="material-icons" aria-hidden='true'>download</i></button>
            let ilkeKarariSayiHeader = k.tur === "İlke Kararı" ? <div>İlke Kararı Sayısı</div> : "";
            let ilkeKarariSayi = k.tur === "İlke Kararı" ? <div>{k.kararSayisi}</div> : "";
            let baglantiliTebligler = [];
            if (k.baglantiliTebligler) {
                var tebligler = k.baglantiliTebligler;
                tebligler.forEach(t => {
                    baglantiliTebligler.push(<li key={t.key}><a href={TebligBase + t.key} target="_blank" rel="noopener noreferrer">{t.value}</a></li>)
                });
            }
            let baglantiliTebligSection = baglantiliTebligler.length > 0 ? <div className='pb-1'>Karar ile Bağlantılı Tebliğler<ul>{baglantiliTebligler}</ul></div> : "";

            let ilgiliKonular = [];
            if (k.ilgiliKonular) {
                var konular = k.ilgiliKonular;
                konular.forEach(t => {
                    ilgiliKonular.push(<span key={t.key} className='pr-1'>{t.value};</span>)
                });
            }
            let ilgiliKonuSection = ilgiliKonular.length > 0 ? <div className='pb-1'>Karar ile İlgili Konular<ul><li>{ilgiliKonular}</li></ul></div> : "";

            results.push(<div key={k.id}>
                <Button variant="link" onClick={() => { openInfo[idx] = !openInfo[idx]; this.setState({ openInfo: openInfo }) }} aria-expanded="false" aria-controls={idx}>{k.title}</Button>
                <span>{fileLink}</span>
                <span>{downloadLink}</span>
                <Collapse in={openInfo[idx]}>
                    <div id={idx}>
                        <div className="d-inline-flex flex-row kararResultHead">
                            <div>Tür</div><div>Kurul Karar Tarihi</div><div>Kurul Toplantı No</div>{ilkeKarariSayiHeader}<div>İlgili Bülten</div><div>Dosya</div>
                        </div>
                        <div className="d-inline-flex flex-row kararResultItem">
                            <div>{k.tur}</div><div>{k.kurulKararTarih}</div><div>{k.kurulToplantiNo}</div>{ilkeKarariSayi}<div>{bultenLink}</div><div>{fileLink}</div>
                        </div>
                        {baglantiliTebligSection}
                        {ilgiliKonuSection}
                    </div>
                </Collapse>
            </div>);
        });

        return (
            <>
                {results}
            </>
        );
    }

    render() {
        const { items, open, openInfo } = this.state;
        let haveItems = items && items.length > 0;
        let contents = haveItems ? this.renderResults(items, openInfo) : "";

        let header = haveItems ? <span className="search-result-title">Kurul Kararları ve İlke Kararları</span> : "";
        return (
            <div className={haveItems ? 'd-block' : 'd-none'}>
                <Button variant="light" onClick={() => this.setState({ open: !open })} className="btn-search-result-title" aria-expanded="false" aria-controls="ilkekararlari">
                    <i className="material-icons">{!open ? "add_box" : "indeterminate_check_box" }</i> {header}
                </Button>
                <Collapse in={open}>
                    <div id="ilkekararlari" className="search-result-list mt-2">
                        {contents}
                    </div>
                </Collapse>
            </div>
        );
    }
}