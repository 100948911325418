import React, { Component } from 'react';
import Config from './settings/config';
import '../css/index.css';
import '../css/page.css';

const API = Config.apiURL;
const QUERY = "Duyuru/List";

export class Duyurular extends Component {
    constructor(props) {
        super(props);

        this.state = { items: [] };
    }

    componentDidMount() {
        this.getDuyurular();
    }

    static renderItems(items) {
        var groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };
        let groupedItems = groupBy(items, 'tarihStr');

        let results = [];

        for (let item in groupedItems) {
            let aItems = groupedItems[item];
            let itemResult = [];
            aItems.forEach(a => {
                let fileLink = <a className="btn btn-link pt-0 pb-0" href={a.link} target='_blank' rel='noopener noreferrer' title='Görüntüle'><i className='material-icons' aria-hidden='true'>description</i> Görüntülemek için tıklayın</a>
                itemResult.push(
                    <div key={a.id} className="pl-2">
                        <div className="d-flex flex-column">
                            <div className="title">
                                <i className="material-icons" aria-hidden="true">stop</i> {a.title}
                            </div>
                            <div>
                                <div className="d-flex flex-row">
                                    <div className="section">({a.kisimNo}. KISIM)</div>
                                    <div>{fileLink}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            results.push(<div key={item}><div className="date">{item}</div>{itemResult}</div>)
        }

        return (
            <div className="d-flex flex-column table announcement">
                {results}
            </div>
        );
    }

    render() {
        const items = this.state.items;
        let contents = items.length > 0 ? Duyurular.renderItems(items) : "";

        return (
            <>
                <div className="mainContainer">
                    <article>
                        {contents}
                    </article>
                </div>
                <div className="mb-1">&nbsp;</div>
            </>
        );
    }

    async getDuyurular() {
        try {
            const response = await fetch(API + QUERY);
            const data = await response.json();
            this.setState({ items: data });
        }
        catch (error) {
            this.setState({ items: [] });
        }
    }
}

