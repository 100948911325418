//const isLocalDebug = Boolean(
//    window.location.hostname === 'localhost' ||
//    // [::1] is the IPv6 localhost address.
//    window.location.hostname === '[::1]' ||
//    // 127.0.0.1/8 is considered localhost for IPv4.
//    window.location.hostname.match(
//        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//    )
//);

//const isProduction = Boolean(
//    window.location.hostname === 'mevzuattest.spk.gov.tr'
//);

//const apiURLs = {
//    "local": "https://localhost:44393/api/",
//    "test": "https://sankapp11.local.spk.gov.tr:4493/api/",
//    "production": "https://spkuygulamalar.local.spk.gov.tr:4493/api/"
//}

var Config = {
    //apiURL: isLocalDebug ? apiURLs.local : (isProduction ? apiURLs.production : apiURLs.test)
    apiURL: "/api/"
};
export default Config;