import React, { Component } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { downloadFile } from './DownloadFile';
import groupBy from './tools/groupBy';
import sortOnKeysWithDefault from './tools/sortOnKeysWithDefault';

export class MevzuatContentList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [], allOpen: false, open: {}, openInfo: {}
        };

        this.handleDownload = this.handleDownload.bind(this);
    }

    componentDidMount() {
        let items = this.props.items;
        let allOpen = this.props.allOpen;
        this.setState({ items: items, allOpen: allOpen });
    }

    handleDownload = (id, fileName, contentSource, e) => {
        e.preventDefault();
        downloadFile(id, fileName, contentSource);
    };

    renderResults(items, allOpen, open, openInfo) {
        var results = [];

        let kisimItems = groupBy(items, 'kisim');

        kisimItems = sortOnKeysWithDefault(kisimItems, "KANUN");

        for (let item in kisimItems) {
            if (allOpen)
                open[item] = true;
            let plusMinus = <i className="material-icons">{!open[item] ? "add_box" : "indeterminate_check_box" }</i>
            let resultTitle = <Button variant='light' onClick={() => { open[item] = !allOpen ? !open[item] : true; this.setState({ open: open }) }} className='btn-search-result-title' aria-expanded='false' aria-controls={item}>{plusMinus} <span className="search-result-title">{item}</span></Button>
            let contentResult = [];
            let kItems = kisimItems[item];
            kItems.forEach(k => {
                let infoIndex = k.id;
                let fileLink = <a className="btn btn-link" href={k.link} target='_blank' rel='noopener noreferrer' title='Görüntüle'><i className="material-icons" aria-hidden='true'>description</i></a>
                let downloadLink = <button className="btn btn-link" onClick={(e) => this.handleDownload(k.contentID, infoIndex, k.contentSource, e)} title='İndir'><i className="material-icons" aria-hidden='true'>download</i></button>

                contentResult.push(<div key={k.id}>
                    <Button variant="link" onClick={() => { openInfo[infoIndex] = !openInfo[infoIndex]; this.setState({ openInfo: openInfo }) }} aria-expanded="false" aria-controls={infoIndex}>{k.title}</Button>
                    <span>{fileLink}</span>
                    <span>{downloadLink}</span>
                    <Collapse in={openInfo[infoIndex]}>
                        <div id={infoIndex}>
                            <div className="d-inline-flex flex-row contentResultHead">
                                <div>Bölüm</div><div>Tür</div><div>Sayı</div><div>Dosya</div>
                            </div>
                            <div className="d-inline-flex flex-row contentResultItem">
                                <div>{k.bolum}</div><div>{k.tur}</div><div>{k.sayi}</div><div>{fileLink}</div>
                            </div>
                        </div>
                    </Collapse>
                </div>);
            });
            results.push(<div key={item}>{resultTitle}<Collapse in={open[item]}><div id={item} className="search-result-list mt-2">{contentResult}</div></Collapse></div>)
        };

        return (
            <>
                { results }
            </>
        );
    }

    render() {
        const { items, allOpen, open, openInfo } = this.state;
        let contents = items && items.length > 0 ? this.renderResults(items, allOpen, open, openInfo) : "";

        return (
            <>
                {contents}
            </>
        );
    }
}