import React, { Component } from 'react';
import { Button, Collapse } from 'react-bootstrap';

import { downloadFile } from './DownloadFile';

const TebligBase = '/Mevzuat/Dosya/';

export class RehberList extends Component {

    constructor(props) {
        super(props);

        this.state = { items: [], open: false, openInfo: {} };

        this.handleDownload = this.handleDownload.bind(this);
    }

    componentDidMount() {
        let items = this.props.items;
        let open = this.props.open;
        this.setState({ items: items, open: open });
    }

    handleDownload = (id, fileName, e) => {
        e.preventDefault();
        downloadFile(id, fileName, "Rehber");
        //this.getFile(id, fileName, "Rehber");
    };

    renderResults(items, openInfo) {
        let results = [];
        items.forEach(k => {
            let idx = k.id;
            let fileLink = <a className="btn btn-link" href={k.link} target='_blank' rel='noopener noreferrer' title='Görüntüle'><i className='material-icons' aria-hidden='true'>description</i></a>
            let downloadLink = <button className="btn btn-link" onClick={(e) => this.handleDownload(k.contentID, idx, e)} title='İndir'><i className="material-icons" aria-hidden='true'>download</i></button>
            //let dLink = k.link.replace('Dosya', 'DosyaKaydet');
            //let downloadLink = <a className="btn btn-link" href={dLink} target='_self' rel='noopener noreferrer' title='İndir'><i className="material-icons" aria-hidden='true'>download</i></a>
            let baglantiliTebligler = [];
            if (k.baglantiliTebligler) {
                var tebligler = k.baglantiliTebligler;
                tebligler.forEach(t => {
                    baglantiliTebligler.push(<li key={t.Key}><a href={TebligBase + t.key} target="_blank" rel="noopener noreferrer">{t.value}</a></li>)
                });
            }
            let baglantiliTebligSection = baglantiliTebligler.length > 0 ? <div className='pt-1 pb-1'>Rehber ile Bağlantılı Tebliğler<ul>{baglantiliTebligler}</ul></div> : "";

            let ilgiliKonular = [];
            if (k.ilgiliKonular) {
                var konular = k.ilgiliKonular;
                konular.forEach(t => {
                    ilgiliKonular.push(<span key={t.key} className='pr-1'>{t.value};</span>)
                });
            }
            let ilgiliKonuSection = ilgiliKonular.length > 0 ? <div className='pb-1'>Rehber ile İlgili Konular<ul><li>{ilgiliKonular}</li></ul></div> : "";

            results.push(<div key={k.id}>
                <Button variant="link" onClick={() => { openInfo[idx] = !openInfo[idx]; this.setState({ openInfo: openInfo }) }} aria-expanded="false" aria-controls={idx}>{k.title}</Button>
                <span>{fileLink}</span>
                <span>{downloadLink}</span>
                <Collapse in={openInfo[idx]}>
                    <div id={idx}>
                        {baglantiliTebligSection}
                        {ilgiliKonuSection}
                    </div>
                </Collapse>
            </div>);
        });

        return (
            <>
                {results}
            </>
        );
    }

    render() {
        const { items, open, openInfo } = this.state;
        let haveItems = items && items.length > 0;
        let contents = haveItems ? this.renderResults(items, openInfo) : "";

        let header = haveItems ? <span className="search-result-title">Rehberler</span> : "";
        return (
            <div className={haveItems ? 'd-block' : 'd-none'}>
                <Button variant="light" onClick={() => this.setState({ open: !open })} className="btn-search-result-title" aria-expanded="false" aria-controls="rehberler">
                    <i className="material-icons">{!open ? "add_box" : "indeterminate_check_box"}</i> {header}
                </Button>
                <Collapse in={open}>
                    <div id="rehberler" className="search-result-list mt-2">
                        {contents}
                    </div>
                </Collapse>
            </div>
        );
    }
}