import React, { Component } from 'react'
import Config from './settings/config'

const API = Config.apiURL;

export class DisplayFile extends Component {
    constructor(props) {
        super(props);

        this.state = { "id" : -1, type: "Mevzuat" };
    }

    componentDidMount() {
        const { params } = this.props.match;
        const type = this.props.type;
        this.setState({ "id": params.id, type: type });

        this.getFile(params.id, type);
    }

    render() {
        return (
            <></>
        );
    }

    async getFile(id, controller) {
        let query = controller + "/File";
        var contentType = "application/pdf"
        await fetch(API + query + "/" + id)
            .then(function (response) {
                contentType = response.headers.get('content-type');
                return response.blob();
            })
            .then(function (data) {
                const file = new Blob(
                    [data],
                    { type: contentType });
                let url = URL.createObjectURL(file);
                window.location.href = url;
            })
            .catch(function (error) { });
    }
}