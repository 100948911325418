import React, { Component } from 'react';
import { Search } from './Search';
import '../css/index.css';

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
          <div className="mainContainer">
              <Search />
              <div className="mb-1">&nbsp;</div>
          </div>
    );
  }
}
