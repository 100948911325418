import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Duyurular } from './components/Duyurular';
import { DisplayFile } from './components/DisplayFile';
import { Page } from './components/Page';
// clear cache on new version
import CacheBuster from './CacheBuster';

import './css/site.css';
import './css/bootstrap-hack.css';
import './css/buttons.css';

export default class App extends Component {
  static displayName = App.name;

    render() {
        return (
            <>
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/Duyurular' component={Duyurular} />
                    <Route path='/Mevzuat/Dosya/:id' component={(props) => <DisplayFile type="Mevzuat" {...props} />} />
                    <Route path='/IlkeKarari/Dosya/:id' component={(props) => <DisplayFile type="IlkeKarari" {...props} />} />
                    <Route path='/Rehber/Dosya/:id' component={(props) => <DisplayFile type="Rehber" {...props} />} />
                    <Route path='/Sayfa/:id' component={(props) => <Page {...props} />} />
                </Layout>
                <CacheBuster>
                    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                        if (loading) return null;
                        if (!loading && !isLatestVersion) {
                            refreshCacheAndReload();
                        }

                        return (
                            <div className="app-version">
                                <code>v{global.appVersion}</code>
                            </div>
                        );
                    }}
                </CacheBuster>
            </>
        );
    }
}
