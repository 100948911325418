import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import '../css/NavMenu.css';
import logo from '../images/spklogo.png';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
      return (
          <header>
              <Navbar bg="light" expand="md" className="navbar-expand-md navbar-toggleable-md navbar-dark navbar-bg border-bottom box-shadow mb-3" fixed="top">
                  <Navbar.Brand className="navbar-brand" href="/">
                      <img id="logo" src={logo} className="d-inline-block" alt="" />
                      <span><i>Sermaye Piyasası Kurulu</i><b>Capital Markets Board of Türkiye</b></span>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="spk-navbar-nav" />
                  <Navbar.Collapse id="spk-navbar-nav" className="d-md-inline-flex flex-md-column-reverse">
                      <Nav className="ml-auto">
                          <Nav.Item>
                              <Nav.Link className="text-dark" href="/" title="Ana Sayfa">Ana Sayfa&nbsp;<i className="material-icons">home</i></Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                              <Nav.Link className="text-dark" href="/Duyurular" title="Güncellemeler">Duyuru&nbsp;<i className="material-icons">campaign</i></Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                              <Nav.Link className="text-dark" href="/Sayfa/yardim" title="Yardım">Yardım&nbsp;<i className="material-icons">help</i></Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                              <Nav.Link className="text-dark" href="https://spk.gov.tr" title="SPK web sitesi" target="_blank" rel="noopener noreferrer">SPK web sitesi&nbsp;<i className="material-icons">business</i></Nav.Link>
                          </Nav.Item>
                      </Nav>
                      <div className="d-flex flex-row w-100">
                          <div className="ml-auto align-self-end site-title">
                              <span>Sermaye Piyasası Mevzuat Sistemi</span>
                          </div>
                          {/*<div className="align-self-end header-links">
                              <Link className="btn btn-sm" to="Home/Index" title="SPK Mevzuat - ana sayfa"><i className="fa fa-book"></i></Link>&nbsp;
                              <a href="https://spk.gov.tr" target="_blank" title="Sermaye Piyasası Kurulu web sitesi" rel="noopener noreferrer"><i className="fa fa-university"></i></a>
                          </div>*/}
                      </div>
                  </Navbar.Collapse>
              </Navbar>
          </header>
      );
  }
}
