import React, { Component } from 'react';
import parse from 'html-react-parser';
import Config from './settings/config';
import '../css/index.css';
import '../css/page.css';

const API = Config.apiURL;
const QUERY = "Page";

export class Page extends Component {
    constructor(props) {
        super(props);

        this.state = { id: 0, title: "", content: "", userFriendlyId: "" };
    }

    componentDidMount() {
        const { params } = this.props.match;
        this.setState({ userFriendlyId: params.id });

        this.getPage(params.id);
    }

    render() {
        const { title, content } = this.state;
        let pcontent = content ? parse(content) : "Sayfa içeriği bulunamadı!";

        return (
            <>
                <div className="mainContainer">
                    <article>
                        <div className="page-title">{title}</div>
                        {pcontent}
                    </article>
                </div>
                <div className="mb-1">&nbsp;</div>
            </>
        );
    }

    async getPage(id) {
        try {
            let url = API + QUERY + "/" + id;
            const response = await fetch(url);
            const data = await response.json();
            this.setState({ id: data.id, title: data.title, content: data.content, userFriendlyId: data.userFriendlyUrl });
        }
        catch (error) {
            this.setState({ id: 0, title: "", content: "", userFriendlyId: "" });
        }
    }
}

